@import './fonts.scss';

@import url('highlight.js/styles/github.css');
@import url('highlight.js/styles/github-dark.css') (prefers-color-scheme: dark);

$blueBright: #0069ff;

:root {
    --m3-container-secondary: #dae2ff;
    --m3-on-container-secondary: #2d4b77;
    --m3-container-primary: #3a5ba9;
    --m3-on-container-primary: #ffffff;

    --body-background: #f8f9fa;
    --body-text-color: #24292f;
    --body-light-muted-color: rgba(155, 162, 176, 0.14);
    --body-muted-text-color: #868686;
    --header-background: #ffffff;
    --header-box-shadow-color: rgba(151, 164, 175, .1);
    --header-logo-color: #303757;
    --header-border-bottom-color: transparent;
    --card-background: #ffffff;
    --card-border-color: #e7eaf3;
    --card-box-shadow-color: rgba(189, 197, 209, .2);
    --card-header-background: var(--card-background);
    --card-header-color: #707579;
    --card-header-tab-color: #7c8286;
    --card-row-separator: #eef0f6;
    --card-pill-background: #efefef;
    --blue-bright: #{$blueBright};
    --blue-bright-background: rgba(56, 139, 253, 0.1);
    --cyan-bright: #3fa5bd;
    --green-bright:  #02977e;
    --yellow-bright: #f9c426;
    --badge-red-color: #ec6471;
    --badge-red-background-color: rgba(155, 82, 89, 0.18);
    --badge-green-color: var(--green-bright);
    --badge-green-background-color: rgba(0, 201, 167, 0.2);
    --badge-grey-color: #7f9ab9;
    --badge-grey-background-color: #ecf0f5;
    --modal-title-background: var(--body-background); // modal section header background
    --modal-border-color: transparent;
    --modal-banner-background: var(--m3-container-secondary);
    --modal-banner-text-color: var(--m3-on-container-secondary);
    --modal-banner-round-background: var(--m3-container-primary);
    --modal-banner-round-icon-fill: var(--m3-on-container-primary);
    --tx-table-thead-alt-background: var(--body-background); // thead under the tabed nav
    --tx-table-details-background-color: rgba(238, 238, 238, 0.2);
    --tx-table-icon-color: #656565;
    --tx-table-icon-error-color: #ec6471;
    --tx-table-icon-error-hover-color: #{darken(#ec6471, 18%)};
    --tx-table-icon-error-background: #fdeeec;
    --tx-messages-message-background: var(--tx-table-details-background-color);
    --indexpage-search-background-color: #ffffff;
    --ambilight-overlay-color: rgba(255, 255, 255, 0.6);
    --nft-preview-image-outline: rgba(0, 0, 0, 0.06);
    --nft-preview-error-card-background: #ffffff;
    --big-blue-button-background: #{lighten($blueBright, 6%)};
    --big-blue-button-hover-background: #{darken($blueBright, 1%)};
    --big-blue-button-disabled-background: #f1f2f4;
    --big-blue-button-disabled-color: #8B8888;
    --stacked-bar-background: #edebeb;
    --stacked-bar-meh: #{darken(#edebeb, 16%)};
    --stacked-bar-red: #D96A65;
    --stacked-bar-green: #599f5e;
    --ui-round-image-color: rgba(155, 162, 176, 0.28);
    --code-viewer-background: var(--card-background);
    --code-viewer-border-color: #dee1ed;
    --code-viewer-tab-inactive-background: #f7f8f9;
    --code-viewer-file-selector-background-color: #f8f9fa;
    --code-viewer-file-selector-border-color: var(--code-viewer-border-color);
    --user-nft-list-item-background: #fafafa;
    --user-nft-list-item-hover-background: #ffffff;
    --user-nft-list-item-box-shadow-blur: 20px;
    --user-nft-list-item-text-muted-color: #828282;
    --page-tx-status-success-color: var(--green-bright);
    --page-tx-status-error-color: #e64040;
    --page-tx-flow-diagram-border-color: #dadcde;

    --chart-grid-color: #2a2a2a;
    --chart-pie-border-color: var(--card-background);
    --chart-skeleton-background-color: #2c2c2c; //var(--body-light-muted-color);
}

@media (prefers-color-scheme: dark) {
    :root {
        --m3-container-secondary: #1e438f;
        --m3-on-container-secondary: #dae2ff;

        // Также этот цвет в theme-color:
        --body-background: #0f0f0f;
        --body-text-color: #cfd5dd;
        --body-light-muted-color: rgba(91, 91, 91, 0.2);
        --body-muted-text-color: #7e7e7e;
        --header-background: #212121;
        --header-logo-color: #eeeeee;
        --header-box-shadow-color: #1010109c;
        --header-border-bottom-color: var(--card-border-color);
        --card-background: #212121;
        --card-border-color: #2f2f2f;
        --card-box-shadow-color: #1010109c;
        --card-header-background: var(--card-background);
        --card-header-color: #bfbfbf;
        --card-header-tab-color: #7c8286;
        --card-row-separator: #2e2e2e;
        --card-pill-background: #333;
        --blue-bright: #{lighten($blueBright, 15%)};
        --blue-bright-background: rgba(77, 152, 254, 0.1);
        --green-bright: #3fb950;
        --cyan-bright: #80d6ea;
        --yellow-bright: #fdd764;
        --badge-red-color: #f87777;
        --badge-red-background-color: rgba(230, 79, 79, 0.18);
        --badge-green-color: #2bb259;
        --badge-green-background-color: rgba(30, 176, 60, 0.18);
        --badge-grey-color: #777;
        --badge-grey-background-color: #2f2f2f;
        --modal-title-background: #1c1b1b;
        --modal-border-color: var(--card-border-color);
        --modal-banner-background: var(--m3-container-secondary);
        --modal-banner-text-color: var(--m3-on-container-secondary);
        --modal-banner-round-background: #3a5ba9;
        --modal-banner-round-icon-fill: #FFFFFF;
        --tx-table-thead-alt-background: #1c1b1b;
        --tx-table-details-background-color: #1a1a1a;
        --tx-table-icon-color: #858585;
        --tx-table-icon-error-color: #f87777;
        --tx-table-icon-error-hover-color: #{lighten(#f87777, 10%)};
        --tx-table-icon-error-background: var(--badge-red-background-color);
        --tx-messages-message-background: #1c1b1b;
        --indexpage-search-background-color: #000000;
        --ambilight-overlay-color: #303030;
        --nft-preview-image-outline: rgba(255, 255, 255, 0.06);
        --nft-preview-error-card-background: radial-gradient(circle, transparent 30%, rgb(18,18,18) 100%);
        --big-blue-button-background: #{darken($blueBright, 2%)};
        --big-blue-button-hover-background: #{lighten($blueBright, 3%)};
        --big-blue-button-disabled-background: #474747;
        --big-blue-button-disabled-color: #AAA;
        --stacked-bar-background: #303030;
        --stacked-bar-meh: #{lighten(#303030, 12%)};
        --stacked-bar-red: linear-gradient(180deg, #A1615E 0%, #834C45 100%);
        --stacked-bar-green: linear-gradient(180deg, #5ea161 0%, #47874e 100%);
        --ui-round-image-color: rgba(91, 91, 91, 0.33);
        --code-viewer-background: #151515;
        --code-viewer-border-color: #2a2a2a;
        --code-viewer-tab-inactive-background: #1c1b1b;
        --code-viewer-file-selector-background-color: rgba(255, 255, 255, 0.06);
        --code-viewer-file-selector-border-color: #353535;
        --user-nft-list-item-background: #2a2a2a;
        --user-nft-list-item-hover-background: #313131;
        --user-nft-list-item-box-shadow-blur: 12px;
        --user-nft-list-item-text-muted-color: #999;
        --page-tx-status-success-color: var(--green-bright);
        --page-tx-status-error-color: #ff706f;
        --page-tx-flow-diagram-border-color: #444444;
    }
}

html {
    font-size: 22px;
  }
  
  @media screen and (max-width: 479px) {
    html {
      font-size: 14px;
    }
}

body {
    font-family: Rubik, Roboto, Ubuntu, Arial, sans-serif;
    background: var(--body-background);
    color: var(--body-text-color);
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: none;
}

a {
    color: var(--blue-bright);
    text-decoration: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

button {
    font-family: Rubik, Roboto, Ubuntu, Arial, sans-serif;
    outline: none;
    appearance: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    background: none;
    outline: none;
    padding: 0;
    margin: 0;
}

input {
    font-family: Rubik, Roboto, Ubuntu, Arial, sans-serif;
    appearance: none !important;
    outline: none;
    font-size: 14px;
    -webkit-appearance: textfield;
    &::placeholder {
        opacity: 1;
    }
    &::-webkit-input-placeholder {
        opacity: 1;
    }
    &::-webkit-search-cancel-button {
        display: none;
    }
    &::-webkit-search-results-button {
        display: none;
    }
    &::-webkit-search-decoration {
        -webkit-appearance: none;
    }
}

input[type=search] {
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &::-webkit-search-decoration {
        -webkit-appearance: none;
    }
}

h1, h2, h3, h4, h5, h6, strong, b {
    font-weight: 500;
}

.muted {
    color: var(--body-muted-text-color);
}

.toast-container {
    position: fixed;
    width: 100%;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9000;
    pointer-events: none;
}

.toast {
    max-width: 380px;
    font-size: 14px;
    padding: 12px 28px;
    border-radius: 6px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
    white-space: nowrap;
    opacity: 0;
    background: #000;
    color: #FFF;
    border-radius: 4px;
    transition: all .3s ease-out;
    line-height: 1.4;
    transform: translateY(-100%);

    &.visible {
        transition: opacity .2s ease-out;
        opacity: 1;
        transform: none;
    }
}

.header {
    display: flex;
    background: var(--header-background);
    box-shadow: 0 1px 10px var(--header-box-shadow-color);
    padding: 8px 0;
    margin-bottom: 18px;
    height: 55px; // 54 + border
    border-bottom: 1px solid var(--header-border-bottom-color);
    box-sizing: border-box;
    position: relative;
    z-index: 80000;
    &__container {
        flex-grow: 1;
        max-width: 1200px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__logo {
        width: 46px;
        height: 46px;
        margin: -10px 0;
        position: relative;
        bottom: -1px;
        color: var(--header-logo-color);
        transition: .1s color ease;
        .header--mobile-search-visible & {
            display: none;
        }
        &:hover {
            color: var(--body-text-color);
        }
    }
}

@media screen and (max-width: 1200px) {
    .header__container {
        padding: 0 8px;
    }
}

.header-search {
    position: relative;
    width: auto;
    font-size: 14px;
    .search__loader {
        transform: scale(0.9);
    }
    .search__loopa {
        position: absolute;
        fill: currentColor;
    }
    .search__input {
        min-width: 340px;
        padding: 9px 34px 9px 36px;
        background: var(--body-light-muted-color);
        color: var(--body-text-color);
        border-radius: 6px;
        border: 2px solid transparent;
        transition: .2s all ease;
        &--expanded {
            min-width: 480px;
            padding-left: 16px;
        }
        &:focus {
            min-width: 480px;
            outline: 0 none;
            background: transparent;
        }
        &::placeholder {
            color: #939394;
        }
        &::-webkit-input-placeholder {
            color: #939394;
        }
    }
    .search-results {
        padding-bottom: 4px;
        padding-top: 4px;
        overflow: hidden;
        &__link {
            margin: 0 0 0 0;
        }
    }
    .search-result {
        &__left {
            margin-left: -4px;
        }
    }
}

.header-search-mobile {
    padding: 15px 6px 15px 12px;
    margin: -12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    color: #797979;
    &__loopa {
        width: 24px;
        height: 24px;
        fill: currentColor;
        margin-bottom: -1px;
    }
    &:hover {
        color: #444;
    }
}

@media screen and (max-width: 480px) {
    .header-search {
        display: none;

        .header--mobile-search-visible & {
            display: flex !important;
        }
    }
    .header-search-mobile {
        display: flex;

        .header--mobile-search-visible & {
            display: none;
        }
    }
}

@media screen and (max-width: 480px) {
    .header-search {
        flex-grow: 1;
        .search__input {
            width: 100%;
            max-width: 100%;
            min-width: 0;
            &:focus {
                max-width: 100%;
                width: 100%;
                min-width: 0;
            }
        }
    }
}

.indexpage-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    padding: 16px;
    box-sizing: border-box;
}
.indexpage-logo {
    display: block;
    width: 180px;
    height: 180px;
    margin: 0 auto 24px;
}

@media screen and (max-width: 480px) {
    .indexpage-container {
        padding: 16px 8px;
    }
}


.content {
    padding: 0 8px 24px 8px;
    max-width: 1200px;
    margin: auto;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--card-background);
    background-clip: border-box;
    border: 1px solid var(--card-border-color);
    border-radius: .5rem;
    box-shadow: 0 .5rem 1.2rem var(--card-box-shadow-color);
    font-size: 14px;
    & + & {
        margin-top: 18px;
    }
}

.card-title {
    padding: 16px;
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    border-radius: .5rem .5rem 0 0;
    border-bottom: 1px solid var(--card-row-separator);
    background: var(--card-header-background);
    color: var(--card-header-color);
    &__link {
        color: inherit;
        font-size: 13px;
        text-decoration: none;
        display: flex;
        align-items: center;
        padding: 6px;
        margin: -12px 4px;
        text-transform: none;
        font-weight: normal;
        opacity: .65;
        transition: .12s all ease;
        &:hover {
            opacity: 1;
            color: var(--blue-bright);
            text-decoration: none;
        }
    }
    &__icon {
        fill: currentColor;
        width: 24px;
        height: 24px;
        margin: -4px 8px -4px 0;
    }
}

@media screen and (max-width: 480px) {
    .header {
        margin-bottom: 12px;
    }
    .card + .card {
        margin-top: 12px;
    }
}

.card-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 16px;
    padding: 12px 0;
    border-bottom: 1px solid var(--card-row-separator);
    overflow: hidden;
    word-wrap: anywhere;
    line-height: 1.5;
    &__name {
        flex-shrink: 0;
        padding-right: 2ex;
        white-space: nowrap;
        width: 100px;
        font-weight: 500;
        .card-row--wide & {
            width: 200px;
        }
    }
    &__value {
        min-width: 100px;
        max-width: 100%;
        &--flex-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: -8px 0;
        }
    }
    &:last-child {
        border-bottom: none;
    }
}

// second-level row:
.card-row__value > .card-row {
    margin: 0;
    border: none;
    padding: 8px 24px 8px 0;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1.4;
    overflow: visible;
    .card-row__name {
        font-weight: normal;
        opacity: .45;
        font-size: 0.9em;
        padding: 0 0 4px 0;
    }
    .skeleton {
        margin-left: -1px;
    }
}

@media screen and (max-width: 480px) {
    .card-row {
        flex-direction: column;
        align-items: flex-start;
        &__name {
            padding-bottom: 5px;
        }
    }
}

.card-main-address {
    overflow-wrap: anywhere;
    word-break: break-all;
}

.card-main-address-badge {
    display: inline-flex;
    font-size: 11px;
    border: 1px solid currentColor;
    padding: 2px 4px;
    border-radius: 3px;
    margin: -10px 3px -10px 0;
    position: relative;
    top: -1px;
    box-sizing: border-box;
    letter-spacing: 0.4px;
    user-select: none;
    &--scam {
        color: var(--badge-red-color);
    }
}

.card-row-wallet-activity {
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    color: #888;
    &::before {
        content: '';
        margin-right: 5px;
        display: block;
        width: 8px;
        height: 8px;
        background: currentColor;
        border-radius: 50%;
        font-size: 0;
    }
    &--active {
        color: var(--green-bright);
    }
    &--frozen {
        color: var(--cyan-bright);
    }
}

.tx-history-empty-panel {
    padding: 24px 0;
    text-align: center;
    font-weight: 500;
}

.tx-history-wrap {
    overflow-x: auto;
    scrollbar-width: none;
    width: 100%;
}

.tx-table-cell-icon {
    display: inline-flex;
    padding: 4px;
    box-sizing: border-box;
    background: var(--body-light-muted-color);
    color: var(--tx-table-icon-color);
    border-radius: 4px;
    cursor: pointer;
    margin: 0 0 0 12px;
    &--error {
        color: var(--tx-table-icon-error-color);
        background: var(--tx-table-icon-error-background);
        &:hover {
            color: var(--tx-table-icon-error-hover-color) !important;
        }
    }
    svg {
        width: 16px;
        height: 16px;
    }
    &:hover {
        color: var(--body-text-color);
    }
}

.tx-table {
    border-collapse: collapse;
    width: 100%;

    thead {
        font-weight: 500;
        color: #6c757e;
        border: none;
        .card--tabbed & {
            background: var(--tx-table-thead-alt-background);
        }
    }

    td, th {
        white-space: nowrap;
        overflow: hidden;
        padding: 10px 0;
        border-top: 1px solid var(--card-border-color);
        font-size: 14px;
        max-width: 250px;
    }

    th {
        text-align: left;
        font-weight: 500;
        border: none;
    }

    tbody.sub-list tr:not(.tx-table-row-details) td {
        border-top: none;
    }

    &__cell {
        display: flex;
        align-items: center;
        max-width: 100%;
        padding: 0 18px;
        box-sizing: border-box;
        width: 100%;
        &--align-right {
            justify-content: flex-end;
        }
        &--align-center {
            justify-content: center;
        }
    }

    &__badge {
        display: inline-block;
        vertical-align: middle;
        padding: 6px 0;
        min-width: 46px;
        box-sizing: border-box;
        text-align: center;
        font-size: 11px;
        font-weight: 500;
        border-radius: 4px;
        &--in {
            color: var(--badge-green-color);
            background-color: var(--badge-green-background-color);
        }
        &--out {
            color: var(--badge-red-color);
            background-color: var(--badge-red-background-color);
        }
        &--service {
            color: var(--badge-grey-color);
            background-color: var(--badge-grey-background-color);
        }
        &--skeleton {
            color: transparent;
            background-color: var(--body-light-muted-color);
        }
    }
}
.tx-table-operation-icon {
    position: absolute;
    right: 1px;
    width: 14px;
    height: 14px;
}

.alert {
    padding: 16px;
    border-radius: 5px;
    color: var(--badge-red-color);
    border: 1px solid var(--badge-red-color);
    background-color: var(--badge-red-background-color);
}

.tx-table-inner-container {
    display: flex;
    flex-wrap: wrap;
}

.tx-table-inner {
    display: inline-flex;
    flex-direction: column;
    padding: 12px 20px;
    max-width: 70%;
    word-wrap: break-word;
    white-space: normal;
    &__header {
        font-weight: 500;
        font-size: 1.05em;
        margin-bottom: 6px;
        display: block;
    }
}


.tx-table-expand-caret {
    position: relative;
    width: 14px;
    height: 14px;
    cursor: pointer;
    padding: 10px;
    margin: -10px;
    &--expanded {
        transform: rotate(180deg);
    }
}

.tx-table-row-details {
    box-shadow: 0 -2px 0 0 var(--card-border-color) inset;
    background: var(--tx-table-details-background-color);
}

.skeleton {
    display: block;
    background: var(--body-light-muted-color);
    border-radius: 36px;
    color: transparent;
    user-select: none;
    pointer-events: none;
    fill: none;
    &--inline {
        display: inline-flex;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
    }
}

.tx-table-cell-skeleton {
    display: block;
    width: 75%;
    background: var(--body-light-muted-color);
    border-radius: 6px;
    height: 1em;
    margin-bottom: -1px;
}

.address-link {
    text-decoration: none;
    border-bottom: 1px solid transparent;
    margin-bottom: -1px;

    // direct disabled child of <router-link> or <a> = clickable
    &.clickable, a > & {
        color: var(--blue-bright);
        &:hover {
            border-bottom-color: currentColor;
        }
    }

    &.muted {
        opacity: .65;
        pointer-events: none;
    }

    &:hover {
        text-decoration: none;
    }
}

.ui-looong {
    white-space: nowrap;
    font-feature-settings: 'tnum';
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 100%;
    min-width: 0;
}

.ui-looong::before {
    flex-grow: 0;
    content: attr(data-loopa);
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui-looong::after {
    flex-shrink: 0;
    content: attr(data-poopa);
}

.tx-page-messages {
    padding: 0 14px;
}
.tx-page-msg {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    align-items: flex-start;

    border-radius: 6px;
    padding: 12px;
    border: 1px solid var(--card-row-separator);
    background: var(--tx-messages-message-background);

    .card-row__name {
        width: 89px;
    }

    .tx-table__badge {
        width: 100%;
        margin-top: 1px;
    }
}
.tx-page-msg-details {
    table {
        border-collapse:collapse;
        td {
            padding: 6px 10px;
            border: none;
            vertical-align: top;
            &:first-child {
                width: 120px;
                padding-left: 0;
                font-weight: 500;
                white-space: nowrap;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .tx-page-messages {
        padding: 0 10px;
    }
    .tx-page-msg {
        flex-direction: column;
        .tx-table__badge {
            margin-bottom: 6px;
            margin-left: -2px;
        }
    }
}

.block-boring-expand {
    display: inline-flex;
    align-items: center;
    font-weight: normal;
    padding: 6px 10px 6px 14px;
    background: var(--body-light-muted-color);
    cursor: pointer;
    border-radius: 24px;
    font-size: 13px;
    svg {
        margin-left: 6px;
        width: 14px;
        height: 14px;
        margin-bottom: -1px;
    }
    &:hover {
        color: var(--blue-bright);
    }
}

.block-nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px 0;
    &__hcontainer {
        display: flex;
        .block-nav__pill--next {
            margin-left: 14px;
        }
    }
    &__vcontainer {
        display: flex;
        flex-direction: column;
        .block-nav__pill {
            margin-bottom: 14px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__pill {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        background: var(--card-background);
        border: 1px solid var(--card-border-color);
        color: var(--body-text-color);
        text-decoration: none;
        border-radius: 6px;
        font-size: 14px;
        box-shadow: 0 .3rem 1rem var(--card-box-shadow-color);
        cursor: pointer;
        &--prev {
            padding-left: 0;
        }
        &--next {
            padding-right: 0;
        }
        &--disabled {
            opacity: .5;
            pointer-events: none;
        }
        svg {
            width: 16px;
            height: 16px;
            margin: 0 8px;
            fill: currentColor;
        }
        &:hover {
            color: var(--blue-bright);
            text-decoration: none;
        }
    }
}

.card-main-qr-button {
    display: inline-flex;
    padding: 6px 12px 6px 4px;
    margin: -6px 0;
    position: relative;
    bottom: -1px;
    color: var(--body-text-color);
    opacity: .65;
    cursor: pointer;
    &__icon {
        width: 12px;
        height: 12px;
        fill: currentColor;
    }
    &:hover {
        opacity: 1;
    }
}

.card-container {
    padding: 0 14px;
}

.nft-link {
    display: inline-flex;
    align-items: center;
    color: currentColor;
    opacity: .9;
    margin-right: 20px;
    margin-bottom: 6px;
    flex-wrap: nowrap;
    max-width: 90%;
    svg {
        flex-shrink: 0;
        fill: currentColor;
        margin-right: 4px;
        opacity: .7;
        height: 1em;
    }
    .address-link {
        max-width: 150px;
        color: currentColor;
        line-height: 1.2;
    }
}

.nft-collection-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 32px;
}

@media all and (min-width: 640px) {
    .nft-collection-card {
        background: none;
        border: none;
        box-shadow: none;
    }
}

@media all and (max-width: 640px) {
    .nft-collection-card {
        margin-top: 0;
        flex-direction: column;
    }
}

.nft-collection-cover-container {
    flex-grow: 1;
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
}

@media all and (max-width: 640px) {
    .nft-collection-cover-container {
        width: 100%;
    }
}


.nft-collection-cover {
    display: block;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    background-color: var(--card-row-separator);
    width: 120px;
    height: 120px;
    border-radius: 12px;
    background-size: cover;
    background-position: center;
}

@media all and (max-width: 640px) {
    .nft-collection-cover {
        object-fit: contain;
        min-width: 100%;
        height: auto;
        max-height: 240px;
        border-radius: 6px 6px 0 0;
    }
}

.nft-collection-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    box-sizing: border-box;
    padding-left: 18px;
    padding-top: 6px;
}
.nft-collection-title {
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 12px;
    margin-top: 0;
}
.nft-collection-description {
    font-size: 17px;
    margin-bottom: 20px;
    line-height: 1.4;
}
.nft-collection-meta {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    line-height: 2;
    margin-top: -4px;
}

@media all and (max-width: 640px) {
    .nft-collection-info {
        padding: 18px 14px 10px 14px;
    }

    .nft-collection-title {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .nft-collection-description {
        font-size: 14px;
        line-height: 1.4;
        opacity: .9;
    }

    .nft-collection-meta {
        flex-direction: column;
        align-items: flex-start;
    }
}

.nft-items-container {
    margin: 32px 0 0 0;
    display: grid;
    grid-column-gap: 28px;
    grid-row-gap: 24px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: 1fr;
    &__loader {
        margin-top: 18px;
    }
}
.nft-preview {
    display: flex;
    flex-direction: column;
    background-color: var(--card-background);
    background-clip: border-box;
    border-radius: .5rem;
    font-size: 14px;
    color: var(--body-text-color);
    overflow: hidden;
    box-shadow:
        0 .5rem 1.2rem var(--card-box-shadow-color),
        0 0 0 1px var(--card-border-color) inset;

    &--loading {
        pointer-events: none;
    }

    &:hover {
        text-decoration: none;
        .nft-preview__title {
            text-decoration: underline;
        }
    }
    &__image {
        flex-shrink: 0;
        aspect-ratio: 1 / 1;
        width: 100%;
        object-fit: cover;
        border-radius: .5rem .5rem 0 0;
        overflow: hidden;
        display: block;
        background-color: var(--ambilight-overlay-color);
        background-size: cover;
        background-position: center;
        background-blend-mode: soft-light;
    }
    &__title {
        padding: 12px 12px 0 12px;
        font-weight: 500;
        font-size: 16px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
    }
    &__meta {
        font-size: 13px;
        padding: 5px 12px 10px 12px;
        font-weight: 400;
        opacity: .8;
        margin-top: auto;
    }
}

@media all and (max-width: 900px) {
    .nft-items-container {
        margin-top: 18px;
        grid-column-gap: 22px;
        grid-row-gap: 20px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media all and (max-width: 480px) {
    .nft-items-container {
        grid-column-gap: 16px;
        grid-row-gap: 14px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.mugen-scroll {
    display: flex;
    &__button {
        text-transform: uppercase;
        cursor: pointer;
        margin: 12px auto;
        text-align: center;
        padding: 10px 22px;
        font-weight: 500;
        background: var(--blue-bright-background);
        color: var(--blue-bright);
        border-radius: 4px;
        font-size: 13px;
        &:hover {
            background: rgba(56, 139, 253, 0.2);
        }
    }
}

.bright-blue-button {
    background: var(--big-blue-button-background);
    color: #FFF;
    font-weight: 500;
    padding: 10px 0;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 6px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    transition: .15s background ease;
    text-decoration: none;
    &:hover {
        text-decoration: none;
        background: var(--big-blue-button-hover-background);
    }
    &--disabled {
        background: var(--big-blue-button-disabled-background);
        color: var(--big-blue-button-disabled-color);
        pointer-events: none;
    }
}

.modal-banner {
    display: flex;
    align-items: flex-start;
    padding: 12px;
    margin: 12px 12px 8px 12px;
    background: var(--modal-banner-background);
    border-radius: 16px;
    &__text {
        line-height: 1.5;
        color: var(--modal-banner-text-color);
    }
}
.modal-banner-icon {
    margin-right: 12px;
    padding: 10px;
    background: var(--modal-banner-round-background);
    border-radius: 50%;
    font-size: 0;
    &__svg {
        fill: var(--modal-banner-round-icon-fill);
        position: relative;
        width: 24px;
        height: 24px;
    }
}
